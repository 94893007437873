import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import UserProfilePage from '../profile/UserProfilePage';
import { useSelector } from 'react-redux';
import colors from '../../theme/colors.json';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: colors.BG_COLOR_1,
  },
}));

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { userProfileData } = useSelector((state: any) => state.profile);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const classes = useStyles();

  return (
    <div id={'userProfileMainContainer'} className={classes.root}>
      <UserProfilePage
        id={'userProfileContainer'}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        userProfileData={userProfileData?.data}
      />
    </div>
  );
};

export default ProfilePage;
