/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Chip,
  Modal,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as ViewMoreInfoIcon } from '../../../assets/icons/svg/view_more.svg';
import ViewMoreDetailsDrawer from './ViewMoreDetailsDrawer';
import { viewMoreAttackInfoApi } from '../../../services/dashboardApis';
import CustomPagination from '../../../components/common/customPagination';

import { ReactComponent as DownloadIcon } from '../../../assets/icons/svg/downloadIcon.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/svg/filterIcon.svg';
import { getSeverityColor } from '../../utils/getColor';
import CustomDate from '../../../components/common/customDate';
import NoAttacksFound from '../../NoAttacksFound';
import FilterPopover from '../../../components/common/customFilter/FilterPopOver';
import { useSelector } from 'react-redux';
import { formatDateWithAMPM } from '../../utils/convertTime';
import CONSTANTS from '../../../constants/constants';
import CustomButton from '../../../components/common/customButton';
import CustomSearchField from '../../../components/common/customSearch/CustomSearchField';
import { getSessionStorageItem } from '../../../encrypt';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '98%',
    overflow: 'hidden',
    height: '100%',
    padding: '0.5rem 1rem 0.5rem 1.5rem',
    [theme.breakpoints.down(600)]: {
      padding: '0rem 1rem 12rem 1rem',
    },
  },
  tableContainer: {
    width: '98%',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1.5),
    '&::-webkit-scrollbar': {
      width: '0rem',
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.DODGER_BLUE,
      borderRadius: '100px',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track': {
      background: colors.BORDER_1,
      borderRadius: '100px',
      border: `0.5rem solid ${colors.DODGER_BLUE}`,
    },
  },
  tableBodyCell: {
    color: `${colors.TX_COLOR_5} !important`,
    fontSize: '1rem',
    borderBottom: 'none !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 180,
    '@media (min-width:600px)': {
      fontSize: '0.9rem !important',
    },
    '@media (min-width:960px)': {
      fontSize: '0.95rem !important',
    },
    '@media (min-width:1280px)': {
      fontSize: '1.05rem !important',
    },
    '@media (min-width:1920px)': {
      fontSize: '1.1rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.6rem !important',
    },
  },
  tableHeadCell: {
    borderTop: `1px solid ${colors.TX_COLOR_6} !important`,
    color: `${colors.CHARCOAL_BLUE} !important`,
    fontSize: '1rem !important',
    '@media (min-width:600px)': {
      fontSize: '0.9rem !important',
    },
    '@media (min-width:960px)': {
      fontSize: '0.95rem !important',
    },
    '@media (min-width:1280px)': {
      fontSize: '1.05rem !important',
    },
    '@media (min-width:1920px)': {
      fontSize: '1.1rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.6rem !important',
    },
  },
  TitleAndFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      gap: '0.5rem',
    },
  },
  filtersAndDownloadBox: {
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    gap: '0.6rem',
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('xs')]: {
      width: '80%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '52%',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      width: '53%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
    },

    '@media (min-width:1024px)': {
      width: '54%',
    },
    '@media (min-width:1200px)': {
      width: '48%',
    },
    '@media (min-width:1800px)': {
      width: '42%',
    },
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      width: '2rem',
      height: '2rem',
      borderRadius: '1rem',
      border: `1px solid ${colors.BORDER_2}`,
      backgroundColor: colors.WHITE,
      color: colors.RED,
    },
    '& .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root': {
      backgroundColor: colors.RED,
      color: colors.GREEN,
      borderRadius: '1rem',
    },
    '& .MuiCheckbox-root:hover .MuiSvgIcon-root': {
      backgroundColor: colors.BRIGHT_BLUE,
    },
  },
  typography: {
    paddingLeft: theme.spacing(2),
    flex: 1,
    color: `${colors.TX_COLOR_4} !important`,
    fontSize: '1.12rem',
  },
  chip: {
    background: `${colors.BG_COLOR_7} !important`,
    color: `${colors.CORAL_RED} !important`,
    marginLeft: theme.spacing(1),
    '@media (min-width:2560px)': {
      fontSize: '1.2rem !important',
      padding: '1rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.4rem !important',
      padding: '1.1rem !important',
    },
  },
  tableBox: {
    height: '86%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  paginationBox: {
    alignSelf: 'center',
  },
  noDataBox: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipSeverity: {
    color: `${colors.WHITE} !important`,
    fontSize: '1rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.2rem !important',
      padding: '1rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.4rem !important',
      padding: '1.1rem !important',
    },
  },
  viewDetailsIcon: {
    color: colors.ROYAL_BLUE,
    fontWeight: 500,
    fontSize: '0.9rem',
    marginRight: '0.4rem',
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.6rem !important',
    },
  },
  iconButton: {
    color: colors.ROYAL_BLUE,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.TRANSPARENT,
    height: '90%',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
}));

const columns = [
  { id: 'serialNumber', label: 'S.No', align: 'center' },
  { id: 'timestamp', label: 'Event Time (IST)', align: 'center' },
  { id: 'remote_address', label: 'Source IP', align: 'center' },
  { id: 'country_name', label: 'Country', align: 'left' },
  { id: 'uri', label: 'URI', align: 'center' },
  { id: 'severity', label: 'Severity', align: 'center' },
  { id: 'more_info', label: 'More Info', align: 'center' },
];

interface AttackInformationTableProps {
  id: string;
  data: any;
  totalRecords: number;
  totalPages: number;
  page: number;
  setPage: any;
  pageSize: number;
  searchInput: string;
  setSearchInput: any;
  searchTerm: string;
  setSearchTerm: any;
  domainId: any;
  startAndEndDateTime: any;
  severity: any;
  setSeverity: any;
  countries: any;
  setCountries: any;
}

const AttackInformationTable: React.FC<AttackInformationTableProps> = ({
  id,
  data,
  totalRecords,
  totalPages,
  page,
  setPage,
  pageSize,
  searchInput,
  setSearchInput,
  searchTerm,
  setSearchTerm,
  domainId,
  startAndEndDateTime,
  severity,
  setSeverity,
  countries,
  setCountries,
}) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { attacksInformationLoading } = useSelector(
    (state: any) => state?.loaders
  );

  const { domain } = useSelector((state: any) => state?.domainData);

  const domainInStorage: any = getSessionStorageItem(CONSTANTS.DOMAIN_OBJ);

  const storedDomain = domainInStorage ? JSON.parse(domainInStorage) : domain;

  const [anchorEl, setAnchorEl] = useState(null);

  const { isDownloadingPdf } = useSelector((state: any) => state?.loaders);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleClickFilters = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setFilterAnchorEl(null);
  };

  const isFitlerOpen = Boolean(filterAnchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickViewDetails = async (rowId) => {
    const rowData: any = await viewMoreAttackInfoApi(
      domainId,
      rowId,
      startAndEndDateTime
    );
    setSelectedRow(rowData);
    setDrawerOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event?.key === CONSTANTS.ENTER_KEY) {
      setSearchTerm(event?.target?.value);
      setPage(1);
    }
  };

  const classes = useStyles();

  const handleClickDownloadBtn = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleSearchClick = () => {
    setSearchTerm(searchInput);
  };

  const handleClearInput = () => {
    setSearchInput('');
    setSearchTerm('');
  };

  const renderAttackInfoTable = () => {
    if (totalRecords > 0 && data?.length > 0) {
      return (
        <Box className={classes.tableBox}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((headCell: any) => (
                    <TableCell
                      key={`${id}-${headCell?.id}`}
                      align={headCell?.align || 'left'}
                      className={classes.tableHeadCell}
                    >
                      {headCell?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  const chipStyles = {
                    background: getSeverityColor(row?.severity),
                  };

                  return (
                    <TableRow hover tabIndex={-1} key={row?.unique_id}>
                      <TableCell
                        align="center"
                        className={classes.tableBodyCell}
                      >
                        {index + 1 + (page - 1) * pageSize}
                      </TableCell>
                      <TableCell
                        align="center"
                        title={formatDateWithAMPM(row?.timestamp)}
                        className={classes.tableBodyCell}
                      >
                        {formatDateWithAMPM(row?.timestamp)}
                      </TableCell>
                      <TableCell
                        title={row?.remote_address}
                        align="center"
                        className={classes.tableBodyCell}
                      >
                        {row?.remote_address}
                      </TableCell>
                      <TableCell
                        title={row?.country_name}
                        className={classes.tableBodyCell}
                      >
                        {row?.country_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        title={row?.uri}
                        className={classes.tableBodyCell}
                      >
                        {row?.uri}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableBodyCell}
                      >
                        <Chip
                          label={row?.severity || 'Unknown'}
                          sx={chipStyles}
                          className={classes.chipSeverity}
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        title={'click here to view more attack information'}
                        align="center"
                        className={classes.tableBodyCell}
                      >
                        <IconButton
                          className={classes.iconButton}
                          onClick={() => handleClickViewDetails(row.unique_id)}
                          size="small"
                        >
                          <span className={classes.viewDetailsIcon}>
                            View Details
                          </span>
                          <ViewMoreInfoIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.paginationBox}>
            <CustomPagination
              page={page}
              totalPages={totalPages}
              handleChangePage={handleChangePage}
            />
          </Box>
        </Box>
      );
    }

    return (
      <div data-testid="test-table-noAttacksInfo" className={classes.noDataBox}>
        <NoAttacksFound id={`${id}-noAttacksInfo`} />
      </div>
    );
  };

  const modeLabel =
    storedDomain?.current_mode === CONSTANTS.MODE_ON
      ? CONSTANTS.BLOCK_MODE
      : CONSTANTS.TRANSPARENT_MODE;

  return (
    <Box id={id} className={classes.mainContainer}>
      <div
        id={`${id}-titleContainer`}
        className={classes.TitleAndFilterContainer}
      >
        <Typography
          className={classes.typography}
          fontWeight={500}
          variant="h2"
        >
          Attack Information
          <Chip label={modeLabel} size="small" className={classes.chip} />
        </Typography>
        <Box className={classes.filtersAndDownloadBox}>
          <CustomSearchField
            id="attackInfosearchInput"
            onKeyDown={handleKeyDown}
            value={searchInput}
            placeholder="Search by Source IP or URI"
            onChange={(e) => setSearchInput(e?.target?.value)}
            onClear={handleClearInput}
            handleSearch={handleSearchClick}
            fullWidth={false}
          />
          <CustomButton
            id={'filterIconBtn'}
            label={CONSTANTS.FILTER_BTN_TEXT}
            backgroundColor={colors.TRANSPARENT}
            variant="outlined"
            textColor={colors.TX_COLOR_7}
            borderColor={colors.TX_COLOR_7}
            startIcon={<FilterIcon />}
            width="100%"
            onClick={handleClickFilters}
          />

          <CustomButton
            id={'downloadIconBtn'}
            label={CONSTANTS.DOWNLOAD_BTN_TEXT}
            variant="outlined"
            backgroundColor={colors.TRANSPARENT}
            textColor={colors.TX_COLOR_7}
            borderColor={colors.TX_COLOR_7}
            width="100%"
            isLoading={isDownloadingPdf}
            startIcon={<DownloadIcon />}
            onClick={handleClickDownloadBtn}
          />
        </Box>
      </div>

      {attacksInformationLoading ? (
        <Box
          id={id}
          data-testid="test-table-refresh-loader"
          className={classes.loaderContainer}
        >
          <div className="refresh-data-loader"></div>
        </Box>
      ) : (
        renderAttackInfoTable()
      )}

      <CustomDate
        id="customDatePicker"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        domainId={domainId}
      />

      <Modal
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        aria-labelledby="view-more-details-drawer"
        aria-describedby="view-more-details-drawer"
      >
        <ViewMoreDetailsDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          selectedRow={selectedRow}
          modeLabel={modeLabel}
        />
      </Modal>
      <FilterPopover
        open={isFitlerOpen}
        onClose={handleCloseFilters}
        page={page}
        domainId={domainId}
        startAndEndDateTime={startAndEndDateTime}
        severity={severity}
        setPage={setPage}
        setSeverity={setSeverity}
        countries={countries}
        setCountries={setCountries}
        searchTerm={searchTerm}
      />
    </Box>
  );
};

export default AttackInformationTable;
