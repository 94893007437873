import { makeStyles } from '@material-ui/core';
import { FormControl, FormLabel, TextField } from '@mui/material';
import colors from '../../../theme/colors.json';
import React from 'react';

interface MUIDateInputProps {
  id: string;
  label?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  min?: string;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  error?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  variant?: 'outlined' | 'filled' | 'standard';
  isEndDate?: boolean;
  startDate?: string;
}

const useStyles = makeStyles({
  inputField: {
    width: '80% !important',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.TRANSPARENT,
      },
      '&:hover fieldset': {
        borderColor: colors.BRIGHT_BLUE,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.BRIGHT_BLUE,
      },
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer !important',
    },
    '& input[type="date"]': {
      color: colors.DIM_GRAY,
      fontSize: '0.9rem',
      '@media (max-width:600px)': {
        fontSize: '0.8rem !important',
      },
      '&::-webkit-datetime-edit': {
        textTransform: 'uppercase',
      },
      '&::-webkit-datetime-edit-fields-wrapper': {
        textTransform: 'uppercase',
      },
      '&::-webkit-datetime-edit-text': {
        textTransform: 'uppercase',
      },
      '&::-webkit-datetime-edit-day-field': {
        textTransform: 'uppercase',
      },
      '&::-webkit-datetime-edit-month-field': {
        textTransform: 'uppercase',
      },
      '&::-webkit-datetime-edit-year-field': {
        textTransform: 'uppercase',
      },
      '&::-webkit-calendar-picker-indicator': {
        marginLeft: '-20px',
      },
      '&::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&[type="date"]': {
        appearance: 'textfield',
      },
      '&::-moz-datetime-edit': {
        textTransform: 'uppercase',
      },
      '&::-ms-datetime-edit': {
        textTransform: 'uppercase',
      },
    },
  },
  label: {
    marginBottom: '0.5rem',
    fontSize: '1rem !important',
    '@media (max-width:600px)': {
      fontSize: '0.9rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize: '1.6rem !important',
    },
  },
});

const MUIDateInput: React.FC<MUIDateInputProps> = ({
  id,
  label,
  value,
  onChange,
  min = '',
  disabled = false,
  className = '',
  required = false,
  error = '',
  fullWidth = true,
  size = 'small',
  variant = 'outlined',
  isEndDate = false,
  startDate = '',
}) => {
  const formatDate = (date: Date | string): string => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')?.[0];
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  const maxDate = formatDate(tomorrow);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    const selected = new Date(selectedDate);
    selected.setHours(0, 0, 0, 0);

    if (selected < tomorrow) {
      if (isEndDate && startDate) {
        const maxDate = new Date(startDate);
        maxDate.setDate(maxDate.getDate() + 6);

        if (selected >= new Date(startDate) && selected <= maxDate) {
          onChange?.(e);
        }
      } else {
        onChange?.(e);
      }
    }
  };

  const getDateConstraints = () => {
    if (!isEndDate || !startDate) return { min: min, max: maxDate };

    const maxEndDate = new Date(startDate);
    maxEndDate.setDate(maxEndDate.getDate() + 6);
    const effectiveMaxDate =
      maxEndDate <= tomorrow ? formatDate(maxEndDate) : maxDate;

    return {
      min: formatDate(startDate),
      max: effectiveMaxDate,
    };
  };

  const dateConstraints = getDateConstraints();
  const classes = useStyles();

  return (
    <FormControl
      fullWidth={fullWidth}
      error={!!error}
      disabled={disabled}
      className={className}
      id={`${id}-formControl`}
    >
      {label && <FormLabel className={classes.label}>{label}</FormLabel>}

      <TextField
        type="date"
        className={classes.inputField}
        value={value}
        onChange={handleDateChange}
        id={`${id}-dateInputField`}
        inputProps={{
          min: dateConstraints?.min,
          max: dateConstraints?.max,
          'data-testid': id,
        }}
        size={size}
        required={required}
        error={!!error}
        fullWidth={fullWidth}
        variant={variant}
      />
    </FormControl>
  );
};

export default MUIDateInput;
