import React, { useMemo, useState } from 'react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  Tooltip,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { formatNumber } from '../../utils/formatNumber';
import NoAttacksFound from '../../NoAttacksFound';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { dateSlashFormat, getDaysDifference } from '../../utils/convertTime';
import { formatXAxis } from '../../utils/formatAxis';
import CONSTANTS, { DURATIONS } from '../../../constants/constants';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    height: 350,
    [theme.breakpoints.down(600)]: {
      width: 650,
    },
    [theme.breakpoints.up('sm')]: {
      height: 380,
    },
    [theme.breakpoints.up('md')]: {
      height: 440,
    },
    [theme.breakpoints.up('lg')]: {
      height: 440,
    },

    [theme.breakpoints.up('xl')]: {
      height: 500,
    },
    [theme.breakpoints.between(900, 960)]: {
      height: 425,
    },
    [theme.breakpoints.between(1536, 1914)]: {
      height: 480,
    },
    [theme.breakpoints.between(1914, 2559)]: {
      height: 480,
    },

    '@media (min-width:2560px)': {
      height: 440,
    },
  },

  root: {
    position: 'relative',
    borderRadius: '0.5rem',
    [theme.breakpoints.down(600)]: {
      overflow: 'auto',
      scrollbarWidth: 'thin',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tooltip: {
    position: 'fixed',
    background: colors.TX_COLOR_1,
    boxShadow: '0px 1px 4px 0px #2C405A35',
    color: colors.WHITE,
    border: '1px solid #ccc',
    padding: '10px',
    pointerEvents: 'none',
    zIndex: 1000,
    borderRadius: '4px',
    transition: 'left 0.1s, top 0.1s',
    transform: 'translate(-50%, -100%)',
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: `6px solid ${colors.TRANSPARENT}`,
    borderRight: `6px solid ${colors.TRANSPARENT}`,
    borderTop: `6px solid ${colors.TX_COLOR_1}`,
    position: 'absolute',
    left: '50%',
    bottom: '-6px',
    transform: 'translateX(-50%)',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendColorBox: {
    width: 20,
    height: 20,
    marginBottom: theme.spacing(0.2),
    marginRight: theme.spacing(1),
    borderRadius: '0.3rem',
  },
  customLegendBox: {
    position: 'absolute',
    top: -45,
    right: 12,
    [theme.breakpoints.down(600)]: {
      top: '100%',
      left: 0,
    },
  },
  customLegend: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  customTooltip: {
    background: colors.TX_COLOR_1,
    color: colors.WHITE,
    padding: '0.8rem',
    borderRadius: '0.5rem',
    boxShadow: '0px 1px 4px 0px #2C405A35',
    pointerEvents: 'none',
    zIndex: 1000,
    transition: 'left 0.1s, top 0.1s',
    transform: 'translate(-50%, -100%)',
  },
  customArrow: {
    width: 0,
    height: 0,
    borderLeft: `6px solid ${colors.TRANSPARENT}`,
    borderRight: `6px solid ${colors.TRANSPARENT}`,
    borderTop: `6px solid ${colors.TX_COLOR_1}`,
    position: 'absolute',
    left: '50%',
    bottom: '-6px',
    transform: 'translateX(-50%)',
  },
  labelName: {
    fontSize: '1rem',
    '@media (min-width:2560px)': {
      fontSize: '1.5rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem',
    },
  },
  axisLabels: {
    fontSize: '0.7rem',
    '@media (min-width:2560px)': {
      fontSize: '1.2rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.5rem',
    },
  },
  labelText: {
    fontSize: '0.65rem',
    '@media (min-width:2560px)': {
      fontSize: '1.1rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.4rem',
    },
  },
  noAttacksFoundBox: {
    display: 'flex',
    height: '80%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pointerStyle: {
    cursor: 'pointer',
  },
  legendColorBgTraffic: {
    backgroundColor: `${colors.INDIGO} !important`,
  },
  legendColorBgAttacks: {
    backgroundColor: `${colors.CORAL_RED} !important`,
  },
}));

const OVERLAP_THRESHOLD = 100;

export const CustomXAxisLabel = ({ classes, viewBox, startDate, endDate }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.only('lg'));
  const isXlScreen = useMediaQuery(theme.breakpoints.only('xl'));

  const width = window?.innerWidth;

  let heightValue;

  switch (true) {
    case width >= 1536 && width <= 1919:
      heightValue = 430;
      break;
    case width >= 900 && width <= 960:
      heightValue = 400;
      break;
    case width >= 2560:
      heightValue = 408;
      break;
    case isXsScreen:
      heightValue = 300;
      break;
    case isSmScreen:
      heightValue = 340;
      break;
    case isMdScreen:
      heightValue = 380;
      break;
    case isLgScreen:
      heightValue = 400;
      break;
    case isXlScreen:
      heightValue = 440;
      break;
    default:
      heightValue = 350;
      break;
  }

  return (
    <svg>
      <g
        transform={`translate(${viewBox?.width / 2 + 100},${
          viewBox?.height / 2 + heightValue
        })`}
      >
        <text
          className={classes.labelName}
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill={colors.BLACK_1}
        >
          Duration
        </text>
        {startDate && (
          <text
            className={classes.labelText}
            x={-viewBox.width / 2 + 60}
            y={-18}
            dy={18}
            textAnchor="middle"
            fill={colors.LAVENDER_GRAY}
          >
            {startDate}
          </text>
        )}
        {endDate && (
          <text
            className={classes.labelText}
            x={viewBox.width / 2 - 100}
            y={-18}
            dy={18}
            textAnchor="middle"
            fill={colors.LAVENDER_GRAY}
          >
            {endDate}
          </text>
        )}
      </g>
    </svg>
  );
};

export const CustomYAxisLabel = ({ classes, viewBox, maxValue }) => {
  let labelText = '';

  switch (true) {
    case maxValue >= 10000000:
      labelText = CONSTANTS.IN_CRORES_TEXT;
      break;
    case maxValue >= 100000:
      labelText = CONSTANTS.IN_LAKHS_TEXT;
      break;
    case maxValue >= 1000:
      labelText = CONSTANTS.IN_THOUSANDS_TEXT;
      break;
    case maxValue >= 100:
      labelText = CONSTANTS.IN_HUNDREDS_TEXT;
      break;
  }

  return (
    <svg>
      <g transform={`translate(${0},${viewBox?.height / 2})`}>
        <text
          className={classes.labelName}
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill={colors.BLACK_1}
          transform="rotate(-90)"
        >
          Count
        </text>
        {labelText && (
          <text
            className={classes.labelText}
            x={6}
            y={14.5}
            dy={16}
            textAnchor="middle"
            fill={colors.BLACK_1}
            transform="rotate(-90)"
          >
            {labelText}
          </text>
        )}
      </g>
    </svg>
  );
};

export const CustomDot = ({ cx, cy, value, name, payload, setTooltipData }) => {
  const isOverlapping =
    Math?.abs(payload?.total_count - payload?.attacks) < OVERLAP_THRESHOLD;

  const classes = useStyles();
  return (
    <svg>
      <circle
        data-testid="custom-dot-tooltip"
        cx={cx}
        cy={cy}
        r={6}
        fill={colors.SAFFRON}
        stroke={colors.WHITE}
        strokeWidth={2}
        className={classes.pointerStyle}
        onMouseEnter={(e) => {
          if (isOverlapping) {
            return;
          }
          setTooltipData({
            show: true,
            x: e?.clientX,
            y: e?.clientY,
            name,
            value,
            time: payload?.end_time,
          });
        }}
        onMouseLeave={() => {
          if (!isOverlapping) {
            setTooltipData({ show: false });
          }
        }}
      />
    </svg>
  );
};

export const CustomLegend = () => {
  const classes = useStyles();
  return (
    <div className={classes.customLegend}>
      <div className={classes.legendItem}>
        <div
          className={`${classes.legendColorBox} ${classes.legendColorBgTraffic}`}
        />
        <Typography variant="body2">Traffic</Typography>
      </div>
      <div className={classes.legendItem}>
        <div
          className={`${classes.legendColorBox} ${classes.legendColorBgAttacks}`}
        />
        <Typography variant="body2">Attacks</Typography>
      </div>
    </div>
  );
};

export const CustomTooltip = ({ active, payload, coordinate, classes }) => {
  if (active && payload && payload?.length) {
    const isOverlapping =
      Math?.abs(payload?.[0]?.value - payload?.[1]?.value) < OVERLAP_THRESHOLD;

    if (isOverlapping) {
      return (
        <div
          className={classes.customTooltip}
          style={{
            left: coordinate?.x,
            top: coordinate?.y - 50,
          }}
        >
          <p>Traffic: {formatNumber(payload?.[0]?.value)}</p>
          <p>Attacks: {formatNumber(payload?.[1]?.value)}</p>
          <div className={classes.customArrow} />
        </div>
      );
    }
  }
  return null;
};

interface AttackTrendlineChartProps {
  startAndEndDate: any;
  data: any;
  label: string;
}

const AttackTrendlineChart: React.FC<AttackTrendlineChartProps> = ({
  startAndEndDate,
  data,
  label,
}) => {
  const classes = useStyles();
  const [tooltipData, setTooltipData] = useState({
    show: false,
    x: 0,
    y: 0,
    name: '',
    value: 0,
    time: '',
  });

  const diffInDays = getDaysDifference(
    startAndEndDate.from,
    startAndEndDate.to
  );

  const hasTotalCount = data?.some((item) => item?.total_count > 0);

  let startDate = '';
  let endDate = '';

  if (
    (data?.length !== 0 && label === DURATIONS.HOURS_12) ||
    label === DURATIONS.HOURS_24
  ) {
    startDate = dateSlashFormat(data?.[0]?.start_time);
    endDate = dateSlashFormat(data?.[data?.length - 1]?.end_time);
  }

  const maxValue = useMemo(() => {
    if (!Array.isArray(data) || data?.length === 0) return 0;
    return Math.max(
      ...data.map((item) => {
        return Math.max(item?.total_count ?? 0, item?.attacks ?? 0);
      })
    );
  }, [data]);

  const theme = useTheme();

  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));

  let interval = 1;
  let angle = 0;

  switch (label) {
    case DURATIONS.HOURS_12:
      interval = 0;
      break;

    case DURATIONS.HOURS_24:
      interval = 0;
      angle = -15;
      if (isLgScreen) {
        angle = 0;
      }
      break;

    case DURATIONS.WEEK:
      interval = 0;
      break;

    case DURATIONS.MONTH:
      interval = 0;
      angle = -35;
      break;

    case DURATIONS.YEAR:
      interval = 0;
      angle = -10;
      if (isLgScreen) {
        angle = 0;
      }
      break;

    case DURATIONS.CUSTOM:
      interval = 0;
      angle = -40;
      if (diffInDays < 2) {
        angle = -20;
      } else if (diffInDays >= 180) {
        angle = 0;
      }
      break;

    default:
      break;
  }

  const calculateTicks = (data) => {
    const length = data?.length;
    if (length <= 10) return data?.map((item) => item?.end_time);

    const step = Math?.max(Math?.floor(length / 12), 1);

    const ticks = [data?.[0]?.end_time];
    for (let i = step; i < length - 1; i += step) {
      ticks?.push(data[i]?.end_time);
    }
    ticks?.push(data[length - 1]?.end_time);

    return ticks;
  };

  if (!hasTotalCount) {
    return (
      <Box className={classes.noAttacksFoundBox}>
        <NoAttacksFound id="noAttacksTrendlineFound" />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.customLegendBox}>
        <CustomLegend />
      </Box>
      {tooltipData?.show && (
        <div
          className={classes.tooltip}
          style={{
            left: `${tooltipData?.x}px`,
            top: `${tooltipData?.y - 10}px`,
          }}
        >
          <p>{`${formatNumber(tooltipData?.value)}`}</p>
          <div className={classes.arrow} />
        </div>
      )}
      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height={'100%'}>
          {data?.length > 0 ? (
            <ComposedChart
              data={data}
              margin={{ top: 20, right: 55, left: 20, bottom: 40 }}
            >
              <svg>
                <defs>
                  <linearGradient id="colorTraffic" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="0%"
                      stopColor="rgba(0, 70, 255, 0.32)"
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor="rgba(79, 70, 187, 0)"
                      stopOpacity={1}
                    />
                  </linearGradient>
                  <linearGradient id="colorAttacks" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="-19.4%"
                      stopColor="rgba(255, 75, 85, 0.32)"
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor="rgba(79, 70, 187, 0)"
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
              </svg>
              <CartesianGrid
                vertical={false}
                horizontal={true}
                stroke={colors.TX_COLOR_2}
              />
              <YAxis
                domain={['auto', 'auto']}
                tickCount={7}
                axisLine={false}
                tickFormatter={(value) => formatNumber(value)}
                tickLine={false}
                label={
                  <CustomYAxisLabel
                    viewBox={undefined}
                    classes={classes}
                    maxValue={maxValue}
                  />
                }
                className={classes.axisLabels}
              />

              <Area
                type="linear"
                dataKey="total_count"
                fill="url(#colorTraffic)"
                stroke="none"
                legendType="none"
              />
              <Area
                type="linear"
                dataKey="attacks"
                fill="url(#colorAttacks)"
                stroke="none"
                legendType="none"
              />

              <XAxis
                dataKey="end_time"
                axisLine={false}
                tickLine={false}
                tickMargin={12}
                angle={angle}
                tickFormatter={(tick, index) =>
                  formatXAxis(tick, index, label, {
                    from: startAndEndDate?.from,
                    to: startAndEndDate?.to,
                  })
                }
                className={classes.axisLabels}
                label={
                  <CustomXAxisLabel
                    classes={classes}
                    viewBox={undefined}
                    startDate={startDate}
                    endDate={endDate}
                  />
                }
                ticks={calculateTicks(data)}
                interval={interval}
              />

              <Line
                type="linear"
                dataKey="total_count"
                stroke={colors.INDIGO}
                dot={(props) => (
                  <CustomDot
                    {...props}
                    name="Traffic"
                    setTooltipData={setTooltipData}
                  />
                )}
                strokeWidth={4}
                name="Traffic"
              />

              <Line
                type="linear"
                dataKey="attacks"
                stroke={colors.CORAL_RED}
                dot={(props) => (
                  <CustomDot
                    {...props}
                    name="Attacks"
                    setTooltipData={setTooltipData}
                  />
                )}
                strokeWidth={4}
                name="Attacks"
              />
              {tooltipData?.show && (
                <div
                  className={classes.tooltip}
                  style={{
                    left: `${tooltipData?.x}px`,
                    top: `${tooltipData?.y - 10}px`,
                  }}
                >
                  <p>{`${formatNumber(tooltipData?.value)}`}</p>
                  <div className={classes.arrow} />
                </div>
              )}

              <Tooltip
                cursor={false}
                content={
                  <CustomTooltip
                    classes={classes}
                    active={undefined}
                    payload={undefined}
                    coordinate={undefined}
                  />
                }
              />
            </ComposedChart>
          ) : (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <NoAttacksFound id={'NoAttacksForTrendline'} />
            </Box>
          )}
        </ResponsiveContainer>
      </div>
    </Box>
  );
};

export default AttackTrendlineChart;
