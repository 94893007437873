import moment from 'moment-timezone';
import CONSTANTS, {
  DATE_TIME_FORMATS,
  DURATIONS,
} from '../../constants/constants';

export const calculateRange = (range): { from: string; to: string } => {
  const end = moment().utc();
  let start;

  switch (range) {
    case DURATIONS.HOURS_12:
      start = end?.clone().subtract(12, 'hours');
      break;
    case DURATIONS.HOURS_24:
      start = end?.clone().subtract(24, 'hours');
      break;
    case DURATIONS.WEEK:
      start = end?.clone().subtract(1, 'week');
      break;
    case DURATIONS.MONTH:
      start = end?.clone().subtract(1, 'month');
      break;
    case DURATIONS.YEAR:
      start = end?.clone().subtract(6, 'months');
      break;
    default:
      start = moment().utc();
      break;
  }

  return {
    from: start?.format(DATE_TIME_FORMATS.FORMAT_4),
    to: end?.format(DATE_TIME_FORMATS.FORMAT_4),
  };
};

export const dateFormat = (date) => {
  if (!moment(date)?.isValid()) {
    return CONSTANTS.INVALID_DATE_TEXT;
  }

  return moment(date)?.format(CONSTANTS.DATE_FORMAT);
};

export const convertAndFormatDate = (date) => {
  if (!moment(date)?.isValid()) {
    return CONSTANTS.INVALID_DATE_TEXT;
  }

  const formattedDate = moment
    .tz(CONSTANTS.ASIA_KOLKATA_ZONE)
    ?.format(CONSTANTS.DATE_SLASH_FORMAT);

  return formattedDate;
};

export const formatAndEncodeDate = (date) => {
  if (!moment(date)?.isValid()) {
    return CONSTANTS.INVALID_DATE_TEXT;
  }
  const formattedTime = date?.format(DATE_TIME_FORMATS.FORMAT_1);
  const offset = date?.format('Z');
  const encodedOffset = offset?.replace(/:/g, '').replace(/\+/g, '%2B');
  return formattedTime.replace(offset, encodedOffset);
};

export const getDateTimeForLabel = (label) => {
  let start;
  const end = moment.tz(CONSTANTS.ASIA_KOLKATA_ZONE);

  switch (label) {
    case DURATIONS.HOURS_12:
      start = formatAndEncodeDate(end?.clone()?.subtract(12, 'hours'));
      break;
    case DURATIONS.HOURS_24:
      start = formatAndEncodeDate(end?.clone()?.subtract(24, 'hours'));
      break;

    case DURATIONS.WEEK:
      start = formatAndEncodeDate(end?.clone()?.subtract(1, 'week'));
      break;

    case DURATIONS.MONTH:
      start = formatAndEncodeDate(end?.clone()?.subtract(1, 'month'));
      break;
    case DURATIONS.YEAR:
      start = formatAndEncodeDate(end?.clone()?.subtract(6, 'months'));
      break;
    default:
      return { from: '', to: '' };
  }
  return {
    from: start,
    to: formatAndEncodeDate(end),
  };
};

export const convertToISTFormat = (date) => {
  if (!moment(date)?.isValid()) {
    return CONSTANTS.INVALID_DATE_TEXT;
  }
  return formatAndEncodeDate(moment(date));
};

export const dateSlashFormat = (date) => {
  if (!moment(date)?.isValid()) {
    return CONSTANTS.INVALID_DATE_TEXT;
  }
  return moment(date)?.format(CONSTANTS.DATE_SLASH_FORMAT);
};

export const getDatePart = (encodedDatetime) => {
  const decodedDatetime = decodeURIComponent(encodedDatetime);

  const dateMoment = moment(decodedDatetime, DATE_TIME_FORMATS.FORMAT_1);

  return dateMoment?.format(CONSTANTS.DATE_SLASH_FORMAT);
};

export const formatDateWithAMPM = (dateString) => {
  if (typeof dateString !== 'string') {
    return CONSTANTS.INVALID_DATE_TEXT;
  }
  const dateMoment = moment.tz(
    dateString,
    DATE_TIME_FORMATS.FORMAT_2,
    CONSTANTS.ASIA_KOLKATA_ZONE
  );

  return dateMoment?.format(DATE_TIME_FORMATS.FORMAT_3);
};

export const getDaysDifference = (date1, date2) => {
  const decodedDate1 = decodeURIComponent(date1);
  const decodedDate2 = decodeURIComponent(date2);

  const format = DATE_TIME_FORMATS.FORMAT_1;
  const startDate = moment.tz(
    decodedDate1,
    format,
    CONSTANTS.ASIA_KOLKATA_ZONE
  );
  const endDate = moment.tz(decodedDate2, format, CONSTANTS.ASIA_KOLKATA_ZONE);

  return Math?.ceil(endDate.diff(startDate, 'days', true));
};

export const isValidUTCFormat = (dateString) => {
  const date = Date.parse(dateString);
  return !isNaN(date) && dateString.endsWith('Z');
};
