/* eslint-disable prettier/prettier */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  TextField,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import CONSTANTS from '../../../constants/constants';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',

      '@media (min-width:2560px)': {
        maxWidth: '80rem',
        height: '70%',
      },
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: colors.BRIGHT_BLUE,
    color: colors.WHITE,
  },
  dialogContent: {
    overflow: 'hidden',
    flex: 1,
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  label: {
    fontWeight: 'bold',
    marginBottom: '4px',
    display: 'block',
    fontSize: '1rem',
    '@media (min-width:2560px)': {
      fontSize: '1.6rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem',
    },
  },
  submitButtonContainer: {
    marginTop: theme.spacing(2),
    alignSelf: 'flex-end',
  },
  required: {
    color: colors.RED,
  },
  closeButton: {
    color: `${colors.WHITE} !important`,
  },
}));
interface ReusableFormProps {
  id: string;
  open: boolean;
  onClose: () => void;
  initialValues: any;
  validationSchema: any;
  onSubmit: any;
  fields: any;
  title: string;
  buttonLabel: string;
  submitButtonPosition?: 'left' | 'right';
}

const ReusableForm: React.FC<ReusableFormProps> = ({
  id,
  open,
  onClose,
  initialValues,
  validationSchema,
  onSubmit,
  fields,
  title,
  buttonLabel = 'Submit',
}) => {
  const classes = useStyles();

  const renderField = (field, values, errors, touched, setFieldValue) => {
    const fieldName = field?.name;
    const fieldValue = fieldName
      ?.split('.')
      .reduce((obj, key) => obj?.[key], values);
    const fieldError = fieldName
      ?.split('.')
      .reduce((obj, key) => obj?.[key], errors);
    const fieldTouched = fieldName
      ?.split('.')
      .reduce((obj, key) => obj?.[key], touched);

    switch (field?.type) {
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Field
                as={Checkbox}
                id={`${id}-${fieldName}`}
                name={fieldName}
                checked={fieldValue}
                onChange={(e) => setFieldValue(fieldName, e?.target?.checked)}
              />
            }
            label={field?.label}
          />
        );
      case 'autocomplete':
        return (
          <>
            <label htmlFor={`${id}-${fieldName}`} className={classes.label}>
              {field?.label}{' '}
              {field?.required && <span className={classes.required}>*</span>}
            </label>
            <Autocomplete
              id={`${id}-${fieldName}`}
              options={field?.options}
              getOptionLabel={field?.getOptionLabel}
              value={field?.getValue(values)}
              onChange={(event, newValue) => {
                field?.onChange(newValue, setFieldValue);
              }}
              onInputChange={field?.onInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={fieldName}
                  size="small"
                  error={fieldTouched && !!fieldError}
                  helperText={fieldTouched && fieldError}
                />
              )}
            />
          </>
        );
      default:
        return (
          <>
            <label htmlFor={`${id}-${fieldName}`} className={classes.label}>
              {field?.label}{' '}
              {field?.required && <span className={classes.required}>*</span>}
            </label>
            <Field
              as={TextField}
              fullWidth
              id={`${id}-${fieldName}`}
              name={fieldName}
              type={field?.type}
              value={fieldValue}
              onChange={(e) => setFieldValue(fieldName, e?.target?.value)}
              placeholder={field?.placeholder}
              error={fieldTouched && !!fieldError}
              helperText={fieldTouched && fieldError}
              variant="outlined"
              disabled={field?.disabled}
            />
          </>
        );
    }
  };

  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      className={classes.dialog}
      data-testid={`${id}-dialog`}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h2">{title}</Typography>
        <IconButton
          title={CONSTANTS.CLOSE_BTN_TEXT}
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form id={`${id}-form`} className={classes.form}>
            <DialogContent className={classes.dialogContent}>
              <Grid container direction="column" spacing={2}>
                {fields?.map((field) => (
                  <Grid item key={field?.name}>
                    {renderField(field, values, errors, touched, setFieldValue)}
                  </Grid>
                ))}

                <Grid item className={classes.submitButtonContainer}>
                  <CustomButton
                    id={`${id}-submit-button`}
                    label={buttonLabel}
                    disabled={isSubmitting}
                    type="submit"
                    margin="0rem"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ReusableForm;
