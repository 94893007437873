import React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles((theme) => ({
  label: {
    color: colors.ROYAL_BLUE,
    flex: 1,
    fontSize: '0.9rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.6rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  },
  value: {
    color: colors.SLATE_BLUE,
    flex: 2,
    wordWrap: 'break-word',
    fontSize: '1rem !important',
    '@media (max-width:600px)': {
      width: '80%',
    },
    '@media (max-width:400px)': {
      width: '65%',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.6rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      width: 320,
      flexDirection: 'column',
    },
  },
  featureList: {
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(2),
    listStyle: 'none',
    flex: 2,
  },
  featureItem: {
    marginBottom: theme.spacing(1),
    color: colors.SLATE_BLUE,
  },
}));

interface DetailItemsProps {
  data: Record<string, string | string[]>;
}

const DetailItems: React.FC<DetailItemsProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      {Object?.keys(data)?.map((key) => (
        <Box key={key} className={classes.itemContainer}>
          <Typography fontWeight={600} className={classes.label}>
            {key}:
          </Typography>
          {Array?.isArray(data[key]) ? (
            <ul className={classes.featureList}>
              {(data[key] as string[])?.map((item) => (
                <li key={`details-${key}`} className={classes.featureItem}>
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <Typography className={classes.value}>{data[key]}</Typography>
          )}
        </Box>
      ))}
    </>
  );
};

export default DetailItems;
