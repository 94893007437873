import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon,
} from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';
import DeleteConfirmationDialog from '../../../components/common/customDeleteBox/DeleteConfirmationDialog';
import {
  getAllCrsRules,
  handleCrsApis,
  uploadCrsRules,
} from '../../../services/crsServices';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../components/common/customButton';
import ReusableTable from '../../../components/common/customTable/ReusableTable';
import AddOrEditRuleComponent from './EditRule';
import { REQUEST_METHODS } from '../../../constants/common';
import {
  handleErrorNotification,
  handleSuccessNotification,
} from '../../utils/notificationHelpers';
import colors from '../../../theme/colors.json';

interface Rule {
  ruleId: number;
  category: string;
  message: string;
  severity: string;
  additional?: {
    impact: string;
    recommendation: string;
  };
}

interface CRSData {
  rules: Rule[];
  totalRulesCount: number;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  uploadInput: {
    display: 'none',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    display: 'inline-block',
  },
  deleteIcon: {
    color: `${colors.VERMILION} !important`,
  },
  editIcon: {
    color: `${colors.BRIGHT_BLUE} !important`,
  },
}));

const CRSRulesDashboard: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRule, setSelectedRule] = useState<Rule | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [crsData, setCrsData] = useState<CRSData>({
    rules: [],
    totalRulesCount: 0,
  });

  const [isLoadingCrsRules, setIsLoadingCrsRules] = useState(false);

  useEffect(() => {
    fetchCrsRules();
  }, []);

  const fetchCrsRules = async () => {
    setIsLoadingCrsRules(true);
    const data = await getAllCrsRules();

    const rules = data?.success ? data?.data?.rules ?? [] : [];
    const totalRulesCount = data?.success
      ? data?.data?.total_rules_count ?? 0
      : 0;

    setCrsData({ rules, totalRulesCount });

    setIsLoadingCrsRules(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleEdit = (rule: Rule) => {
    setSelectedRule(rule);
    setIsAdding(false);
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setSelectedRule(null);
    setIsAdding(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRule(null);
    setIsAdding(false);
  };

  const handleDelete = (ruleId: number) => {
    setRuleToDelete(ruleId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (ruleToDelete !== null) {
      const response: any = await handleCrsApis(
        REQUEST_METHODS.DELETE,
        null,
        ruleToDelete
      );

      if (response?.success) {
        handleSuccessNotification(dispatch, response?.message);
        fetchCrsRules();
      } else {
        handleErrorNotification(dispatch, response?.message);
      }

      setCrsData((prevData) => ({
        ...prevData,
        rules:
          prevData?.rules?.filter((rule) => rule?.ruleId !== ruleToDelete) ||
          [],
      }));
    }
    setDeleteDialogOpen(false);
    setRuleToDelete(null);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setRuleToDelete(null);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      setFile(event?.target?.files?.[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const response = await uploadCrsRules(file);
      if (response?.success) {
        handleSuccessNotification(dispatch, response?.message);
        fetchCrsRules();
        setFile(null);
      } else {
        handleErrorNotification(dispatch, response?.message);
      }
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setPage(0);
  };

  const Cell = ({ title, value, classes }) => (
    <Typography className={classes.ellipsis} title={title} variant="body2">
      {value}
    </Typography>
  );

  const filteredRules = useMemo(() => {
    return crsData?.rules?.filter(
      (rule) =>
        rule?.category?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        rule?.message?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        rule?.severity?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        rule?.ruleId?.toString()?.includes(searchQuery)
    );
  }, [crsData?.rules, searchQuery]);

  const paginatedRules = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return filteredRules?.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredRules, page, rowsPerPage]);

  const ImpactCell = (row: Rule) => (
    <Cell
      title={row?.additional?.impact}
      value={row?.additional?.impact}
      classes={classes}
    />
  );

  const RecommendationCell = (row: Rule) => (
    <Cell
      title={row?.additional?.recommendation}
      value={row?.additional?.recommendation}
      classes={classes}
    />
  );

  const ActionsCell = ({ row, handleEdit, handleDelete, classes }) => (
    <>
      <IconButton
        title="Edit Rule"
        data-testid="editCrsRuleIcon"
        className={classes.editIcon}
        onClick={() => handleEdit(row)}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        title="Delete Rule"
        className={classes.deleteIcon}
        data-testid="deleteCrsRuleIcon"
        onClick={() => handleDelete(row?.ruleId)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
  const columns = [
    { id: 'ruleId', label: 'Rule ID', minWidth: 100 },
    { id: 'category', label: 'Category', minWidth: 120 },
    { id: 'message', label: 'Message', minWidth: 200 },
    { id: 'severity', label: 'Severity', minWidth: 100 },
    {
      id: 'impact',
      label: 'Impact',
      minWidth: 150,
      format: ImpactCell,
    },
    {
      id: 'recommendation',
      label: 'Recommendation',
      minWidth: 150,
      format: RecommendationCell,
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      format: (row) => (
        <ActionsCell
          row={row}
          classes={classes}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ),
    },
  ];

  const renderUploadButton = () => (
    <Box display="flex" alignItems="center" mr={2}>
      <input
        accept=".csv,.json"
        className={classes.uploadInput}
        id="raised-button-file"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="raised-button-file">
        <CustomButton
          component="span"
          id={'uploadCrsBtn'}
          label={'Upload CRS'}
          width={'100%'}
          startIcon={<UploadIcon />}
        />
      </label>
      {file && (
        <CustomButton
          id={'uploadCrsFileBtn'}
          label={CONSTANTS.UPLOAD_BTN_TEXT}
          onClick={handleUpload}
        />
      )}
    </Box>
  );
  return (
    <Box className={classes.root}>
      <ReusableTable
        id="crsRulesTable"
        title="CRS Rules Dashboard"
        columns={columns}
        data={paginatedRules}
        totalCount={filteredRules?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onSearch={handleSearch}
        searchPlaceholder="Search Rules"
        onAddItem={handleAdd}
        buttonLabel="Add Rule"
        isLoading={isLoadingCrsRules}
        renderCustomCell={(column: any, row: Rule) =>
          column.format ? column.format(row) : row[column.id]
        }
        renderUploadButton={() => renderUploadButton()}
      />

      <AddOrEditRuleComponent
        open={openDialog}
        onClose={handleCloseDialog}
        rule={selectedRule}
        onSave={handleCloseDialog}
        isAdding={isAdding}
        fetchCrsRules={fetchCrsRules}
        selectedRule={selectedRule}
      />
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        title={CONSTANTS.IS_DELETE_RULE}
        description={CONSTANTS.DELETE_RULE_CONFIRMATION_MSG}
      />
    </Box>
  );
};

export default CRSRulesDashboard;
