/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Container, Typography, Checkbox, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import cybersophyLogo from '../../assets/icons/svg/logo.svg';
import { fetchFileText } from '../../services/UploadFile';
import CONSTANTS from '../../constants/constants';
import CustomButton from '../common/customButton';
import { v4 as uuidv4 } from 'uuid';
import colors from '../../theme/colors.json';

const ContentArea = styled('div')({
  height: '100%',
  maxHeight: 400,
  padding: '3.5rem',
  paddingTop: '2rem',
  overflowY: 'auto',
  color: colors.BG_COLOR_5,
  backgroundColor: colors.PALE_SKY_BLUE,
  marginBottom: '1rem',
  marginTop: '1rem',
  borderRadius: '1rem',
  '&::-webkit-scrollbar-thumb': {
    minHeight: '40px',
  },
  '@media (min-width:1500px)': {
    maxHeight: 750,
  },
});

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '2rem',
    overflowY: 'auto',
    paddingBottom: '3rem',
    '@media (min-width:1500px)': {
      maxWidth: '90% !important',
    },
  },
  modalStyles: {
    backgroundColor: `${colors.WHITE} !important`,
    outline: 'none !important',
    border: 'none !important',
    boxShadow: 'none',
  },
  logo: {
    width: '20%',
    marginBottom: '20px',
  },
  box: {
    padding: '1rem',
    borderRadius: '10px',
  },
  contentAreaBox: {
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: colors.PALE_SKY_BLUE,
    borderRadius: '1rem',
    padding: '0.01rem',
    marginTop: '2rem',
    paddingRight: '2rem',
  },
  backdrop: {
    backgroundColor: `${colors.WHITE} !important`,
    height: '100vh',
  },
  eulaTitle: {
    fontSize: '1.4rem !important',
    fontStyle: 'normal',
    color: `${colors.BRIGHT_BLUE} !important`,
    '@media (min-width:1500px)': {
      fontSize: '1.8rem !important',
    },
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    color: colors.BG_COLOR_5,
    fontWeight: 400,
    marginLeft: '-10px !important',
    '@media (min-width:1500px)': {
      fontSize: '1.65rem',
    },
  },
  buttonBox: {
    marginTop: '20px',
  },
  paragraph: {
    fontSize: '1.2rem !important',
    '@media (min-width:1500px)': {
      fontSize: '1.65rem !important',
    },
  },
}));

interface EulaModalProps {
  isOpen: boolean;
  onAccept: () => void;
  onReject: () => void;
}

const EulaModal: React.FC<EulaModalProps> = ({
  isOpen,
  onAccept,
  onReject,
}) => {
  const [agree, setAgree] = useState(false);
  const [eulaParagraphs, setEulaParagraphs] = useState<string[]>([]);
  const classes = useStyles();

  useEffect(() => {
    const fetchEulaText = async () => {
      try {
        const response = await fetchFileText();
        const paragraphs = response?.split('\n');
        setEulaParagraphs(paragraphs);
      } catch (error) {
        setEulaParagraphs([CONSTANTS.FAILED_TO_LOAD_EULA_MSG]);
      }
    };

    if (isOpen) {
      fetchEulaText();
    }
  }, [isOpen]);

  const handleCheckboxChange = (event) => {
    setAgree(event?.target?.checked);
  };

  const currentYear = new Date().getFullYear();

  if (isOpen) {
    return (
      <Box className={classes.backdrop}>
        <Container maxWidth="lg" className={classes.container}>
          <img
            className={classes.logo}
            src={cybersophyLogo}
            alt="cybersophy-logo"
          />

          <Box className={classes.box}>
            <Typography
              fontWeight={600}
              className={classes.eulaTitle}
              variant="h4"
              align="center"
            >
              End-User License Agreement (EULA)
            </Typography>
            <Box className={classes.contentAreaBox}>
              <ContentArea>
                {eulaParagraphs?.map((paragraph) => {
                  const updatedParagraph = paragraph?.replace(
                    /© CYBERSOPHY 2023/g,
                    `© CYBERSOPHY ${currentYear}`
                  );

                  return (
                    <Typography
                      className={classes.paragraph}
                      key={`paragraph-${uuidv4()}`}
                      variant="body1"
                      component="p"
                    >
                      {updatedParagraph?.replace(/\\n/g, '')}
                    </Typography>
                  );
                })}
              </ContentArea>
            </Box>
            <div className={classes.checkboxContainer}>
              <Checkbox
                id="agree-checkbox"
                checked={agree}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="agree-checkbox">
                I have read and agree to the End-User License Agreement.
              </label>
            </div>
            <Box className={classes.buttonBox}>
              <CustomButton
                id={'declineBtn'}
                variant="outlined"
                backgroundColor={colors.TRANSPARENT}
                textColor={colors.BRIGHT_BLUE}
                padding="1% 7% 1% 7%"
                label={CONSTANTS.DECLINE_BTN_TEXT}
                onClick={onReject}
              />
              <CustomButton
                id={'acceptBtn'}
                label={CONSTANTS.ACCEPT_BTN_TEXT}
                padding="1.1% 7%"
                disabled={!agree}
                onClick={onAccept}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    );
  } else {
    return null;
  }
};

export default EulaModal;
