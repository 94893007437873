/* eslint-disable prettier/prettier */
import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import ReusableListComponent from './ReusableListComponent';
import './chartGlobalStyles.css';
import { owaspTop10Colors } from '../../utils/getColor';
import useResponsiveDimensions from '../../../components/common/useResponsiveDimensions';
import NoAttacksFound from '../../NoAttacksFound';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  leftPane: {
    width: '95%',
    marginRight: '1rem',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  rightPane: {
    marginLeft: '1rem',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  tooltip: {
    position: 'absolute',
    padding: '10px',
    zIndex: 1000,
    backgroundColor: colors.DARK_GRAY,
    color: colors.WHITE,
    borderRadius: '0.5rem',
    transform: 'translate(-50%, -100%)',
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: `10px solid ${colors.TRANSPARENT}`,
    borderRight: `10px solid ${colors.TRANSPARENT}`,
    borderTop: `10px solid ${colors.DARK_GRAY}`,
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  noDataBox: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipText: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
}));

export const CustomTooltip = ({ active, payload, coordinate }) => {
  const classes = useStyles();

  if (active && payload && payload?.length) {
    const { x, y } = coordinate;
    return (
      <div
        className={classes.tooltip}
        style={{
          left: x + 100,
          top: y,
        }}
      >
        <span
          className={classes.tooltipText}
        >{`${payload?.[0]?.name} : ${payload?.[0]?.value}`}</span>
        <div className={classes.arrow}></div>
      </div>
    );
  }

  return null;
};
interface OwaspTop10MappingChartProps {
  data: any;
}

const OwaspTop10MappingChart: React.FC<OwaspTop10MappingChartProps> = ({
  data,
}) => {
  const classes = useStyles();
  const { outerRadius } = useResponsiveDimensions();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(600));

  const allValuesZero = data?.every((item) => item?.value === 0);

  if (allValuesZero || data?.length === 0) {
    return (
      <Box className={classes.noDataBox}>
        <NoAttacksFound id={'noOwaspTop10Mapping'} />
      </Box>
    );
  }

  return (
    <Box
      data-testid="owaspTop10Categories-test-reusable-root"
      className={classes.root}
    >
      <Box className={classes.leftPane}>
        <ResponsiveContainer width="100%" height={isSmallScreen ? 240 : 400}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={outerRadius}
              fill={colors.TX_COLOR_9}
              dataKey="value"
              isAnimationActive={false}
              activeShape={false}
            >
              {data?.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${entry?.name}`}
                    fill={owaspTop10Colors[index % owaspTop10Colors?.length]}
                  />
                );
              })}
            </Pie>
            <Tooltip
              content={
                <CustomTooltip
                  coordinate={undefined}
                  active={undefined}
                  payload={undefined}
                />
              }
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box className={classes.rightPane}>
        <ReusableListComponent
          id="owaspTop10Categories"
          data={data}
          title="Data List"
          leftColumnHeader="List"
          rightColumnHeader="Value"
          showFilter={false}
          width="100%"
          boxShadow="none"
          colors={owaspTop10Colors}
          showLegendDot={true}
        />
      </Box>
    </Box>
  );
};

export default OwaspTop10MappingChart;
