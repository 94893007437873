import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Select,
  FormControl,
  Popover,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  TextField,
  InputAdornment,
  Divider,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CalendarTodayIcon } from '../../../assets/icons/svg/calender.svg';
import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';
import colors from '../../../theme/colors.json';

import './customDate.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDurationData,
  setStartAndEndDateTime,
} from '../../../redux/ui/uiActions';
import {
  convertAndFormatDate,
  convertToISTFormat,
  dateSlashFormat,
  getDatePart,
  getDateTimeForLabel,
} from '../../../screen/utils/convertTime';
import CONSTANTS, { WEEKDAY_LABELS } from '../../../constants/constants';
import { durations } from '../../initial/initialButtons';
import CustomButton from '../customButton';
import { getSessionStorageItem, setSessionStorageItem } from '../../../encrypt';
import { months } from '../../initial/months';
import { handleErrorNotification } from '../../../screen/utils/notificationHelpers';
import { getMonthDetails } from './calendarUtils';
import { years } from '../../initial/years';

const useStyles = makeStyles((theme) => ({
  datePickerPopover: {
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      height: 400,
      overflow: 'auto',
      scrollbarWidth: 'thin',
    },
    [theme.breakpoints.up('xl')]: {
      height: 550,
    },
  },
  menuItem: {
    fontSize: '0.8rem !important',
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.95rem !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
    borderBottom: '1px solid #e0e0e0 !important',
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  durationSelect: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.1rem solid ${colors.BRIGHT_BLUE} !important`,
      borderRadius: '0.5rem',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      display: 'flex',
      color: colors.BRIGHT_BLUE,
      fontSize: '0.9rem !important',
      [theme.breakpoints.up('xs')]: {
        fontSize: '0.9rem !important',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.95rem !important',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem !important',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem !important',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.2rem !important',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.5rem !important',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem !important',
      },
    },
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },

  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  selectRoot: {
    flexGrow: 1,
  },
  downArrow: {
    position: 'absolute',
    right: 1,
    pointerEvents: 'none',
  },
  dateInput: {
    marginRight: '0.5rem',
    '& .MuiInputBase-root': {
      marginRight: '0.5rem',
      [theme.breakpoints.down(600)]: {
        height: 36,
      },
    },
  },
}));

interface DurationPickerProps {
  setPage: any;
}

const DurationPicker: React.FC<DurationPickerProps> = ({ setPage }) => {
  const classes = useStyles();

  const [selectedDuration, setSelectedDuration] = useState(
    getSessionStorageItem(CONSTANTS.TIME_DURATION) ?? durations?.[0]?.value
  );

  const [isCustomDate, setIsCustomDate] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { isRefresh } = useSelector((state: any) => state.loaders);
  const [storedCustomDate, setStoredCustomDate] = useState<string>(
    getSessionStorageItem(CONSTANTS.CUSTOM_LABEL) || ''
  );

  const [isFromTo, setIsFromTo] = useState(
    getSessionStorageItem(CONSTANTS.IS_FROM_TO)
      ? getSessionStorageItem(CONSTANTS.IS_FROM_TO) === CONSTANTS.IS_FROM_TO
      : true
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [nextMonth, setNextMonth] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastClickedDate, setLastClickedDate] = useState<any>(null);

  const dispatch = useDispatch();

  const stored: any = getSessionStorageItem(CONSTANTS.CUSTOM_LABEL);

  useEffect(() => {
    if (!isFromTo) {
      setEndDate(startDate);
    }
  }, [isFromTo, startDate]);

  useEffect(() => {
    const changeInDuration = () => {
      const startAndEndDate = getDateTimeForLabel(
        selectedDuration?.split('-')?.[0]
      );
      dispatch(setStartAndEndDateTime({ ...startAndEndDate }));
    };
    if (!selectedDuration?.includes(CONSTANTS.CUSTOM_LABEL)) {
      changeInDuration();
    }

    setStoredCustomDate(stored);
  }, [selectedDuration, dispatch, isRefresh, stored]);

  const renderCalendar = (month, isNextMonth) => {
    const monthDetails = getMonthDetails(month);

    if (!monthDetails) return null;
    const { daysInMonth, firstDayOfMonth, prevMonthDays } = monthDetails;

    const days: any[] = [];

    const createDayElement = (key, className, dayNumber, onClick) => (
      <div key={key} className={className} onClick={onClick}>
        {dayNumber}
      </div>
    );

    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      days?.push(
        createDayElement(
          `prev-${prevMonthDays - i}`,
          'day other-month',
          prevMonthDays - i,
          undefined
        )
      );
    }

    const today = new Date();
    today?.setHours(0, 0, 0, 0);

    const getDayClassNames = (
      isSelected,
      isStart,
      isEnd,
      isToday,
      isFuture
    ) => {
      let className = 'day';

      if (isSelected || isStart || isEnd) {
        className += ' selected';
        if (isStart) className += ' start';
        if (isEnd) className += ' end';
        if (isSelected && !isStart && !isEnd) className += ' middle';
      }

      if (isToday) className += ' today';
      if (isFuture) className += ' disabled';

      return className;
    };

    const getDateFlags = (currentDate) => {
      const isSelected =
        startDate &&
        endDate &&
        currentDate >= startDate &&
        currentDate <= endDate;
      const isToday = currentDate?.getTime() === today?.getTime();
      const isStart =
        startDate && currentDate?.getTime() === startDate?.getTime();
      const isEnd = endDate && currentDate?.getTime() === endDate?.getTime();
      const isFuture = currentDate > today;

      return {
        isSelected,
        isStart,
        isEnd,
        isToday,
        isFuture,
      };
    };
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(month?.getFullYear(), month?.getMonth(), i);
      currentDate?.setHours(0, 0, 0, 0);

      const { isSelected, isStart, isEnd, isToday, isFuture } =
        getDateFlags(currentDate);

      const className = getDayClassNames(
        isSelected,
        isStart,
        isEnd,
        isToday,
        isFuture
      );

      const onClick = !isFuture
        ? () => handleDateClick(currentDate)
        : undefined;
      days?.push(createDayElement(i, className, i, onClick));
    }

    const totalDays = days?.length;
    for (let i = 1; totalDays + i <= 42; i++) {
      days?.push(
        createDayElement(`next-${i}`, 'day other-month', i, undefined)
      );
    }

    return (
      <div className="calendar">
        <div className="calendar-header">
          <div className="month-year-selector">
            <Select
              value={month?.getMonth()}
              onChange={(e) => handleMonthChange(e, isNextMonth)}
              className={classes.select}
            >
              {months?.map((monthName, index) => (
                <MenuItem key={monthName} value={index}>
                  {monthName}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={month?.getFullYear()}
              onChange={(e) => handleYearChange(e, isNextMonth)}
              className={classes.select}
            >
              {years?.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="weekdays">
          {WEEKDAY_LABELS?.map((day) => (
            <div key={day}>{day}</div>
          ))}
        </div>

        <div className="days">{days}</div>
      </div>
    );
  };

  const handleDoneClick = () => {
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    startDateTime?.setHours(0, 0, 0);
    endDateTime?.setHours(23, 59, 59);

    const startDateStr = convertToISTFormat(startDateTime);
    const endDateStr = convertToISTFormat(endDateTime);

    setSessionStorageItem(
      CONSTANTS.CUSTOM_LABEL,
      JSON.stringify({
        from: startDateStr,
        to: endDateStr,
      })
    );

    if (startDateStr === endDateStr) {
      handleErrorNotification(dispatch, CONSTANTS.END_DATE_ERROR);
    } else {
      dispatch(
        setStartAndEndDateTime({
          from: startDateStr,
          to: endDateStr,
        })
      );

      setAnchorEl(null);
      setIsCustomDate(false);
    }
  };

  const handleDateClick = (date) => {
    if (!isFromTo) {
      setStartDate(date);
      setEndDate(date);
      return;
    } else {
      const now: any = new Date();
      const isDoubleClick = lastClickedDate && now - lastClickedDate < 300;

      const handleDoubleClick = () => {
        setStartDate(date);
        setEndDate(date);
        setLastClickedDate(null);
      };

      const handleSingleClick = () => {
        if (!startDate || date < startDate) {
          setStartDate(date);
          setEndDate(date);
        } else if (date > startDate) {
          setEndDate(date);
        } else {
          if (date?.getTime() === endDate?.getTime()) {
            return;
          } else {
            setEndDate(date);
          }
        }
        setLastClickedDate(new Date());
      };

      if (isDoubleClick) {
        handleDoubleClick();
      } else {
        handleSingleClick();
      }
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth?.setMonth(currentMonth?.getMonth() - 1))
    );
    setNextMonth(new Date(nextMonth?.setMonth(nextMonth?.getMonth() - 1)));
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth?.setMonth(currentMonth?.getMonth() + 1))
    );
    setNextMonth(new Date(nextMonth?.setMonth(nextMonth?.getMonth() + 1)));
  };

  const handleOpenCustomSelectedDate = () => {
    setAnchorEl(null);
    setIsCustomDate(true);
  };

  const handleOpenCustomDate = (event) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
    setIsCustomDate(true);
  };

  const handleTimeDurationChange = (e) => {
    const parsedValue = JSON.parse(e?.target?.value);

    setSelectedDuration(parsedValue?.value);

    setSessionStorageItem(CONSTANTS.TIME_DURATION, parsedValue?.value);

    if (parsedValue?.label === CONSTANTS.CUSTOM_DURATION) {
      handleOpenCustomDate(e);
    }

    dispatch(setDurationData(e?.target?.value));
    setPage(1);
  };

  const handleCloseCustomDate = () => {
    setAnchorEl(null);
    setIsCustomDate(false);
  };

  const handleMonthChange = (event, isNextMonth) => {
    const newMonth = new Date(isNextMonth ? nextMonth : currentMonth);
    newMonth?.setMonth(event?.target?.value);
    if (isNextMonth) {
      setNextMonth(newMonth);
    } else {
      setCurrentMonth(newMonth);
      setNextMonth(
        new Date(new Date(newMonth)?.setMonth(newMonth?.getMonth() + 1))
      );
    }
  };

  const handleYearChange = (event, isNextMonth) => {
    const newMonth = new Date(isNextMonth ? nextMonth : currentMonth);
    newMonth?.setFullYear(event?.target?.value);
    if (isNextMonth) {
      setNextMonth(newMonth);
    } else {
      setCurrentMonth(newMonth);
      setNextMonth(
        new Date(new Date(newMonth)?.setMonth(newMonth?.getMonth() + 1))
      );
    }
  };

  const renderDateLabel = (
    customLabel: string,
    startDate: Date,
    endDate: Date
  ) => {
    const fromDate =
      JSON.parse(customLabel)?.from ?? convertAndFormatDate(startDate);
    const toDate = JSON.parse(customLabel)?.to ?? convertAndFormatDate(endDate);

    return (
      <>
        <span className="selected-date">{getDatePart(fromDate)}</span>
        <span className="selected-date">to</span>
        <span className="selected-date">{getDatePart(toDate)}</span>
      </>
    );
  };

  const renderSingleDateLabel = (startDate, storedCustomDate) => {
    const labelDate = storedCustomDate
      ? getDatePart(
          JSON.parse(getSessionStorageItem(CONSTANTS.CUSTOM_LABEL))?.from ||
            convertAndFormatDate(startDate)
        )
      : convertAndFormatDate(startDate);

    return <span className="selected-date">{labelDate}</span>;
  };

  return (
    <div className="custom-duration-select">
      <FormControl variant="outlined" fullWidth>
        <div className={classes.selectWrapper}>
          <Select
            inputProps={{ 'data-testid': 'duration-select-label' }}
            className={`duration-select-component ${classes.selectRoot} ${classes.durationSelect}`}
            labelId="duration-select-label"
            value={selectedDuration}
            onChange={handleTimeDurationChange}
            label=""
            renderValue={(selected) => {
              const selectedOption = durations?.find(
                (option) => option?.value === selected
              );
              return selectedOption ? selectedOption?.label : '';
            }}
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => setIsDropdownOpen(false)}
          >
            {durations?.map((option) => (
              <MenuItem
                className={classes.menuItem}
                key={option?.label}
                value={JSON.stringify(option)}
              >
                <div className="duration-menu-items">
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={selectedDuration === option?.value}
                      className="radio-button"
                    />
                    {option?.label}
                  </Box>
                  {option?.label === CONSTANTS.CUSTOM_DURATION &&
                    selectedDuration?.includes(CONSTANTS.CUSTOM_LABEL) && (
                      <div
                        data-testid="customDateRange"
                        className="custom-date-range"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenCustomSelectedDate();
                        }}
                      >
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <CalendarTodayIcon />
                          <Box
                            width={'100%'}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                          >
                            {isFromTo ? (
                              <>
                                {getSessionStorageItem(CONSTANTS.CUSTOM_LABEL)
                                  ? renderDateLabel(
                                      getSessionStorageItem(
                                        CONSTANTS.CUSTOM_LABEL
                                      ),
                                      startDate,
                                      endDate
                                    )
                                  : renderSingleDateLabel(
                                      startDate,
                                      storedCustomDate
                                    )}
                              </>
                            ) : (
                              renderSingleDateLabel(startDate, storedCustomDate)
                            )}
                          </Box>
                        </Box>
                      </div>
                    )}
                </div>
              </MenuItem>
            ))}
          </Select>
          <DownArrow
            style={{
              transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            className={classes.downArrow}
          />
        </div>
      </FormControl>
      <Popover
        className="date-picker-modal"
        open={isCustomDate}
        anchorEl={anchorEl}
        onClose={handleCloseCustomDate}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={`date-picker-container ${classes.datePickerPopover}`}>
          <div className="date-picker-content">
            <div className="date-picker-header">
              <FormControl className="radio-group-and-date-container">
                <RadioGroup
                  row
                  value={isFromTo ? CONSTANTS.IS_FROM_TO : CONSTANTS.TODAY}
                  onChange={(e) => {
                    setIsFromTo(e?.target?.value === CONSTANTS.IS_FROM_TO);
                    setSessionStorageItem(
                      CONSTANTS.IS_FROM_TO,
                      e?.target?.value
                    );
                    if (e?.target?.value === CONSTANTS.TODAY) {
                      const today = new Date();
                      setStartDate(today);
                      setEndDate(today);
                    }
                  }}
                  className="date-type-selector"
                >
                  <FormControlLabel
                    className="custom-form-control-label"
                    value="fromTo"
                    control={<Radio />}
                    label={CONSTANTS.FROM_TO_TEXT}
                  />
                  <FormControlLabel
                    data-testid="singleDay"
                    className="custom-form-control-label"
                    value="today"
                    control={<Radio />}
                    label={CONSTANTS.SINGLE_DAY_TEXT}
                  />
                </RadioGroup>
              </FormControl>
              <div className="date-btn-container">
                <TextField
                  className={classes.dateInput}
                  size="small"
                  value={
                    isFromTo
                      ? `${dateSlashFormat(startDate)} - ${dateSlashFormat(
                          endDate
                        )}`
                      : dateSlashFormat(startDate)
                  }
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleCloseCustomDate}>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomButton
                  id="doneBtn"
                  label={CONSTANTS.DONE_BTN_TEXT}
                  onClick={handleDoneClick}
                />
              </div>
            </div>
            <Divider className="duration-picker-divider" />
            <div className="calendar-container">
              <div className="calendars">
                <IconButton
                  className="nav-button prev"
                  onClick={handlePrevMonth}
                >
                  <ArrowBackIosNewIcon data-testid="ArrowBackIosNewIcon" />
                </IconButton>
                {renderCalendar(currentMonth, false)}
                {renderCalendar(nextMonth, true)}
                <IconButton
                  className="nav-button next"
                  onClick={handleNextMonth}
                >
                  <ArrowForwardIosIcon data-testid="ArrowForwardIosIcon" />
                </IconButton>
              </div>
            </div>
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default DurationPicker;
