/* eslint-disable prettier/prettier */
import React from 'react';
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as SearchIcon } from '../../../assets/icons/svg/Search.svg';
import { makeStyles } from '@material-ui/core';
import { useSharedStyles } from '../../../styles/reuseStyles';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles((theme) => ({
  searchField: {
    '& .MuiOutlinedInput-root': {
      padding: '0.5rem 1rem',
      '& fieldset': {
        borderColor: colors.LIGHT_GRAY,
      },
      '&:hover fieldset': {
        borderColor: colors.BRIGHT_BLUE,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.BRIGHT_BLUE,
      },
    },
    '& .MuiInputLabel-root': {
      color: colors.BRIGHT_BLUE,
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: colors.LIGHT_GRAY,
    },
    '& .MuiInputBase-input::placeholder': {
      fontStyle: 'normal',
      fontSize: '1rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.72rem',
      },
      [theme.breakpoints.down(400)]: {
        fontSize: '0.56rem',
      },
    },
  },
  icon: {
    cursor: 'pointer',
  },
}));
interface CustomSearchFieldProps extends Omit<TextFieldProps, 'onChange'> {
  id: string;
  value: string;
  onChange: any;
  onClear?: () => void;
  placeholder: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
  handleSearch?: any;
}

const CustomSearchField: React.FC<CustomSearchFieldProps> = ({
  id,
  value,
  onChange,
  onClear,
  placeholder = 'Search...',
  startAdornment,
  endAdornment,
  handleSearch,
  fullWidth = true,
  ...props
}) => {
  const handleClear = () => {
    if (onClear) {
      onClear();
    } else {
      onChange({ target: { value: '' } });
    }
  };

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  return (
    <TextField
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      fullWidth={fullWidth}
      variant="outlined"
      className={`${classes.searchField} ${sharedClasses.commonHeight}`}
      size="small"
      InputProps={{
        classes: { root: sharedClasses.commonHeight },

        startAdornment: startAdornment ?? (
          <InputAdornment onClick={handleSearch} position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
        endAdornment:
          endAdornment ??
          (value && (
            <InputAdornment
              onClick={handleClear}
              position="end"
              data-testid={`${id}-clear-icon`}
            >
              <ClearIcon className={classes.icon} />
            </InputAdornment>
          )),
      }}
      {...props}
    />
  );
};

export default CustomSearchField;
