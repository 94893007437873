import { IS_WAF_ADMIN } from '../../constants/actions';

const initialAuthState = {
  isWafAdmin: false,
};

export const authActionReducer = (state = initialAuthState, action) => {
  if (action?.type === IS_WAF_ADMIN) {
    return {
      ...state,
      isWafAdmin: action?.payload,
    };
  }
  return state;
};
