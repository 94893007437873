/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Autocomplete } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';
import { attacksInformationApi } from '../../../services/dashboardApis';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import CONSTANTS from '../../../constants/constants';
import colors from '../../../theme/colors.json';
import { SEVERITY_LEVELS } from '../../initial/defaultData';

const useStyles = makeStyles((theme) => ({
  modalBox: {
    padding: theme.spacing(2),
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
    marginTop: '10%',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  container: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  row: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelApplyButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  searchTerm: string;
  severity: string[];
  setSeverity: any;
  countries: string[];
  setCountries: any;
  page: number;
  setPage: any;
  domainId: string;
  startAndEndDateTime: any;
}

const FilterModal: React.FC<FilterModalProps> = ({
  searchTerm,
  severity,
  setSeverity,
  countries,
  setCountries,
  open,
  onClose,
  setPage,
  page,
  domainId,
  startAndEndDateTime,
}) => {
  const classes = useStyles();
  const [countriesOptions, setCountriesOptions] = useState<string[]>([]);
  const dispatch = useDispatch();

  const handleSeverityChange = (event, newSeverity) => {
    setSeverity(newSeverity);
  };

  const handleInputChange = (event, value) => {
    if (value && !countriesOptions?.includes(value)) {
      setCountriesOptions((prevOptions) => [...prevOptions, value]);
    }
  };

  const handleCountriesChange = (event, newValue) => {
    setCountries(newValue);
  };

  const handleClearFilters = () => {
    setSeverity([]);
    setCountries([]);
    attacksInformationApi(
      dispatch,
      page,
      domainId,
      startAndEndDateTime,
      searchTerm,
      [],
      []
    );
    onClose();
  };

  const handleClickApplyFilters = () => {
    setPage(1);
    const lowerCaseCountries = countries?.map((val: string) =>
      val.toLowerCase()
    );
    const lowerCaseSeverities = severity?.map((val: string) =>
      val.toLowerCase()
    );
    const params = {
      countriesFilter: lowerCaseCountries?.join(','),
      severitiesFilter: lowerCaseSeverities?.join(','),
    };
    attacksInformationApi(
      dispatch,
      page,
      domainId,
      startAndEndDateTime,
      searchTerm,
      params?.countriesFilter,
      params?.severitiesFilter
    );
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box className={classes.modalBox}>
          <Box className={classes.container}>
            <Box className={classes.row}>
              <Typography variant="h2" gutterBottom>
                Severity:
              </Typography>
              <Autocomplete
                size="small"
                multiple
                fullWidth
                id="severity"
                options={SEVERITY_LEVELS}
                value={severity}
                onChange={handleSeverityChange}
                popupIcon={<DownArrow />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select severity"
                  />
                )}
              />
            </Box>
            <Box className={classes.row}>
              <Typography variant="h2" gutterBottom>
                Country:
              </Typography>
              <Autocomplete
                size="small"
                fullWidth
                multiple
                id="countries"
                options={countriesOptions}
                value={countries}
                onChange={handleCountriesChange}
                onInputChange={handleInputChange}
                popupIcon={<DownArrow />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search countries"
                  />
                )}
              />
            </Box>
            <Box className={classes.buttonsContainer}>
              <CustomButton
                id={'clearFiltersBtn'}
                label={CONSTANTS.CLEAR_FILTERS_BTN_TEXT}
                backgroundColor={colors.TRANSPARENT}
                textColor={colors.BRIGHT_BLUE}
                textDecoration="underline"
                padding="0"
                onClick={handleClearFilters}
              />
              <Box className={classes.cancelApplyButtons}>
                <CustomButton
                  backgroundColor={colors.BG_COLOR_4}
                  textColor={colors.TX_COLOR_4}
                  id={'filtersCancelBtn'}
                  margin="0rem 0.5rem"
                  label={CONSTANTS.CANCEL_BTN_TEXT}
                  onClick={onClose}
                />
                <CustomButton
                  id={'applyFiltersBtn'}
                  label={CONSTANTS.APPLY_BTN_TEXT}
                  onClick={handleClickApplyFilters}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
};

export default FilterModal;
