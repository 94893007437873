/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import CustomizedBreadcrumbs from '../../components/common/breadcrumbs';
import { PlaceholderComponent } from './PlaceHolderComponent';
import StatsCards from './StatsCards';
import { useDispatch, useSelector } from 'react-redux';
import GeoMap from './geoMapChart/GeoMap';
import AttacksBySeverity from './attacksBySeverity/AttacksBySeverity';
import {
  attackTrendLineChartApi,
  attacksInformationApi,
  combinedResponsesApi,
} from '../../services/dashboardApis';
import AttackTrendlineChart from './charts/AttackTrendlineChart';
import OwaspTop10MappingChart from './charts/OwaspTop10MappingChart';
import AttacksByCategoryComponent from './charts/AttacksByCategoryComponent';
import ReusableListComponent from './charts/ReusableListComponent';
import HTTPRequestMethod from './charts/HttpRequestMethodComponent';
import AttackInformationTable from './attackInformation/AttackInformationTable';
import DomainControl from './DomainControl';
import CONSTANTS from '../../constants/constants';
import { getDatePart, isValidUTCFormat } from '../utils/convertTime';
import { makeStyles } from '@material-ui/core';
import { getSessionStorageItem } from '../../encrypt';
import colors from '../../theme/colors.json';

const trafficFilterOptions = [
  { value: 'attack', label: 'Attack traffic' },
  { value: 'legit', label: 'Legit traffic' },
];

const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('xs')]: {
      width: '98.5%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '99%',
    },
  },
  gridContainer: {
    paddingRight: theme.spacing(1.5),
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4.5),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const Dashboard: React.FC = () => {
  const [geoMapFilter, setGeoMapFilter] = useState<string>(
    CONSTANTS.ATTACK_TEXT
  );
  const [severity, setSeverity] = useState([]);
  const [countries, setCountries] = useState([]);

  const { combinedResponse } = useSelector((state: any) => state?.ui);

  const { domain } = useSelector((state: any) => state?.domainData);
  const {
    isRefresh,
    geoLocationLoading,
    attackTrendlineLoading,
    attacksInformationLoading,
    combinedLoader,
  } = useSelector((state: any) => state?.loaders);

  const {
    attackInformationData,
    attacksTrendlineData,
    durationData,
    startAndEndDateTime,
  } = useSelector((state: any) => state?.ui);

  const [customStartAndEnd, setCustomStartAndEnd] =
    useState(startAndEndDateTime);

  const duration =
    getSessionStorageItem(CONSTANTS.TIME_DURATION) ?? durationData;

  const timeRange = duration?.split('-');
  const timeline = timeRange?.[0];

  const [timeLabel, setTimeLabel] = useState<string>(timeRange[3]);
  const domainInStorage: any = getSessionStorageItem(CONSTANTS.DOMAIN_OBJ);

  const storedDomain = domainInStorage ? JSON.parse(domainInStorage) : domain;

  let startAndEnd = startAndEndDateTime;

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');

  const pageSize = 5;

  const totalPages = Math?.ceil(attackInformationData?.total / pageSize);

  const handleApiCalls = () => {
    attackTrendLineChartApi(
      dispatch,
      storedDomain?.domain_id,
      startAndEnd,
      timeline
    );
    combinedResponsesApi(dispatch, storedDomain?.domain_id, startAndEnd);
  };

  useEffect(() => {
    const storedCustomDate = getSessionStorageItem(CONSTANTS.CUSTOM_LABEL)
      ? {
          from: JSON.parse(getSessionStorageItem(CONSTANTS.CUSTOM_LABEL))?.from,
          to: JSON.parse(getSessionStorageItem(CONSTANTS.CUSTOM_LABEL))?.to,
        }
      : startAndEndDateTime;

    if (timeline === CONSTANTS.CUSTOM_LABEL && storedCustomDate) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      startAndEnd = storedCustomDate;

      setCustomStartAndEnd(storedCustomDate);

      const singleDate =
        getDatePart(startAndEnd?.from) === getDatePart(startAndEnd?.to);

      const timeLabelFormat = singleDate
        ? `${getDatePart(startAndEnd.from)}`
        : `${getDatePart(startAndEnd?.from)}-${getDatePart(startAndEnd?.to)}`;

      setTimeLabel(timeLabelFormat);
    } else {
      startAndEnd = startAndEndDateTime;

      setCustomStartAndEnd(startAndEndDateTime);
      setTimeLabel(timeRange[3]);
    }

    if (storedDomain?.domain_id) {
      handleApiCalls();
    }
  }, [isRefresh, storedDomain?.domain_id, startAndEndDateTime]);

  useEffect(() => {
    if (storedDomain?.domain_id) {
      const isUTCTime = isValidUTCFormat(startAndEnd?.from);
      attacksInformationApi(
        dispatch,
        page,
        storedDomain?.domain_id,
        isUTCTime ? customStartAndEnd : startAndEnd,
        searchTerm,
        countries,
        severity
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isRefresh,
    page,
    searchTerm,
    storedDomain?.domain_id,
    startAndEndDateTime,
  ]);

  const classes = useStyles();

  return (
    <Box className={classes.dashboardContainer}>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} md={6.5}>
          <CustomizedBreadcrumbs id={'dashboardBreadcrumbs'} />
        </Grid>
        <Grid item xs={12} md={5.5}>
          <DomainControl id="domainSelect" domain={domain} setPage={setPage} />
        </Grid>
        <Grid item xs={12}>
          <StatsCards statsData={combinedResponse?.cardsData} id="statsCards" />
        </Grid>
        <Grid item xs={12} md={9}>
          <PlaceholderComponent
            id="geoMapPlaceHolder"
            loading={geoLocationLoading}
            filterValue={geoMapFilter}
            setFilterValue={setGeoMapFilter}
            filterOptions={trafficFilterOptions}
            marginBottom={1}
            text="Attack Heat Map"
            height={{ xs: 480, sm: 500, md: 520, lg: 550, xl: 640 }}
          >
            <GeoMap
              traffic={geoMapFilter}
              heatMapData={combinedResponse?.geoMapData[geoMapFilter]}
              id={'geoLocationMap'}
            />
          </PlaceholderComponent>
        </Grid>
        <Grid item xs={12} md={3}>
          <PlaceholderComponent
            id="severityPlaceHolder"
            loading={combinedLoader}
            text="Attacks By Severity"
            height={{ xs: 460, sm: 500, md: 520, lg: 550, xl: 640 }}
          >
            <AttacksBySeverity
              id="attacksBySeverity"
              totalCount={combinedResponse?.severityDetails?.totalCount}
              attacksBySeverity={
                combinedResponse?.severityDetails?.attacksBySeverity
              }
            />
          </PlaceholderComponent>
        </Grid>

        <Grid item xs={12} md={4}>
          <PlaceholderComponent
            id="responseCodesPlaceHolder"
            loading={combinedLoader}
            text="Response Code"
            height={{ xs: 460, sm: 500, md: 520, lg: 550, xl: 600 }}
          >
            <ReusableListComponent
              id="responseCodes"
              data={combinedResponse?.requestStats?.responseCodes}
              leftColumnHeader="Code"
              rightColumnHeader="Count"
              showFilter={true}
              width="100%"
              title={'Response Code'}
              legendDotColor={colors.ROYAL_BLUE}
            />
          </PlaceholderComponent>
        </Grid>
        <Grid item xs={12} md={8}>
          <PlaceholderComponent
            id="trendLinePlaceHolder"
            loading={attackTrendlineLoading}
            text="Attack Traffic"
            description={timeLabel}
            height={{ xs: 480, sm: 500, md: 520, lg: 550, xl: 600 }}
          >
            <AttackTrendlineChart
              startAndEndDate={customStartAndEnd}
              data={attacksTrendlineData}
              label={timeline}
            />
          </PlaceholderComponent>
        </Grid>

        <Grid item xs={12} md={8}>
          <PlaceholderComponent
            id="owaspTop10PlaceHolder"
            loading={combinedLoader}
            text="OWASP Top 10 Mapping"
            height={{ xs: 750, sm: 560, md: 520, lg: 540, xl: 550 }}
          >
            <OwaspTop10MappingChart
              data={combinedResponse?.requestStats?.owaspTop10Mapping}
            />
          </PlaceholderComponent>
        </Grid>
        <Grid item xs={12} md={4}>
          <PlaceholderComponent
            id="categoryPlaceHolder"
            loading={combinedLoader}
            text="Attack Categories"
            height={{ xs: 480, sm: 500, md: 520, lg: 540, xl: 550 }}
          >
            <AttacksByCategoryComponent
              attacksByCategory={combinedResponse?.attacksByCategoryData}
            />
          </PlaceholderComponent>
        </Grid>
        <Grid item xs={12} md={4}>
          <PlaceholderComponent
            id="userAgentsPlaceHolder"
            loading={combinedLoader}
            text="User Agent"
            height={{ xs: 470, sm: 480, md: 500, lg: 520, xl: 560 }}
          >
            <ReusableListComponent
              id="userAgents"
              data={combinedResponse?.requestStats?.userAgents}
              leftColumnHeader="User Agent"
              rightColumnHeader="Count"
              showFilter={false}
              width="100%"
              title={'User Agents'}
              showLegendDot={false}
              showSequence={true}
            />
          </PlaceholderComponent>
        </Grid>
        <Grid item xs={12} md={4}>
          <PlaceholderComponent
            id="uriHitsPlaceHolder"
            loading={combinedLoader}
            text="Top URI Hits"
            height={{ xs: 470, sm: 480, md: 500, lg: 520, xl: 560 }}
          >
            <ReusableListComponent
              id="uriHits"
              data={combinedResponse?.requestStats?.uriHits}
              leftColumnHeader="URI"
              rightColumnHeader="Count"
              showFilter={false}
              showLegendDot={false}
              showSequence={true}
              width="100%"
              title={'Top URI Hits'}
            />
          </PlaceholderComponent>
        </Grid>
        <Grid item xs={12} md={4}>
          <PlaceholderComponent
            id="requestMethodsPlaceHolder"
            loading={combinedLoader}
            text="HTTP Request Method"
            height={{ xs: 470, sm: 480, md: 500, lg: 520, xl: 560 }}
          >
            <HTTPRequestMethod
              rawData={combinedResponse?.requestStats?.requestMethods}
            />
          </PlaceholderComponent>
        </Grid>

        <Grid item xs={12}>
          <PlaceholderComponent
            id="attackInfoPlaceHolder"
            loading={attacksInformationLoading}
            isFetchingData={true}
            padding={0}
            text=""
            height={{ xs: 750, sm: 550, md: 540, lg: 550, xl: 600 }}
          >
            <AttackInformationTable
              id={'attacksInfoTable'}
              data={attackInformationData?.data}
              totalRecords={attackInformationData?.total}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              domainId={storedDomain?.domain_id}
              searchInput={searchInput}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              setSearchInput={setSearchInput}
              totalPages={totalPages}
              severity={severity}
              setSeverity={setSeverity}
              countries={countries}
              setCountries={setCountries}
              startAndEndDateTime={customStartAndEnd}
            />
          </PlaceholderComponent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
