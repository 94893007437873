/* eslint-disable prettier/prettier */
import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import useResponsiveDimensions from '../../../components/common/useResponsiveDimensions';
import { makeStyles } from '@material-ui/core';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '1rem',
    '@media (min-width:2560px)': {
      fontSize: '1.5rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem',
    },
  },
}));

interface DonutChartProps {
  id: string;
  percentage: any;
  severity: string;
}

const DonutChart: React.FC<DonutChartProps> = ({
  id,
  percentage,
  severity,
}) => {
  const data = [
    { name: 'Completed', value: percentage },
    { name: 'Remaining', value: 100 - percentage },
  ];

  const { width, height } = useResponsiveDimensions();

  const innerRadius = width / 2.6;
  const outerRadius = width / 2;

  const classes = useStyles();

  const getGradient = (severity) => {
    switch (severity) {
      case 'High':
        return 'url(#highGradient)';
      case 'Medium':
        return 'url(#mediumGradient)';
      case 'Low':
        return 'url(#lowGradient)';
      default:
        return colors.TX_COLOR_9;
    }
  };

  const getTextColor = (severity) => {
    switch (severity) {
      case 'High':
        return colors.CORAL_RED;
      case 'Medium':
        return colors.AMBER;
      case 'Low':
        return colors.BRIGHT_AZURE;
      default:
        return colors.TX_COLOR_9;
    }
  };

  return (
    <PieChart id={id} width={width} height={height}>
      <defs>
        <linearGradient id="highGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: colors.CORAL_RED, stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: colors.SAFFRON, stopOpacity: 1 }}
          />
        </linearGradient>
        <linearGradient id="mediumGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: colors.AMBER, stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: colors.LEMON_YELLOW, stopOpacity: 1 }}
          />
        </linearGradient>
        <linearGradient id="lowGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{ stopColor: colors.PALE_GREEN, stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: colors.BRIGHT_AZURE, stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <Pie
        id={`${id}-severityDonut`}
        data={data}
        cx="50%"
        cy="50%"
        tooltipType="none"
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill={colors.TX_COLOR_9}
        dataKey="value"
      >
        {data?.map((entry, index) => (
          <Cell
            key={`${id}-severityCell-${entry?.name}`}
            fill={index === 0 ? getGradient(severity) : colors.TX_COLOR_2}
          />
        ))}
        <Label
          id={`${id}-severityLabel`}
          value={`${percentage?.toFixed(2)}%`}
          position="center"
          fill={getTextColor(severity)}
          className={classes.label}
          style={{ fontWeight: 'bold' }}
          data-testid={`${id}-severityLabel`}
        />
      </Pie>
    </PieChart>
  );
};

export default DonutChart;
