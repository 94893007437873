/* eslint-disable prettier/prettier */
import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '../../../assets/icons/svg/Home.svg';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import CONSTANTS from '../../../constants/constants';
import { useSelector } from 'react-redux';
import colors from '../../../theme/colors.json';
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.grey[100];
  return {
    backgroundColor: colors.TRANSPARENT,
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: colors.BLUE_GRAY,
    fontWeight: theme.typography.fontWeightRegular,
    '& .MuiChip-label': {
      paddingTop: theme.spacing(0.5),
    },
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },

    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
    fontSize: '0.9rem',
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.95rem !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
  };
}) as typeof Chip;

const useStyles = makeStyles(() => ({
  container: {
    width: '98%',
    
  },
  breadcrumbs: {
    '& .MuiBreadcrumbs-separator': {
      margin: -10,
      paddingTop: 2,
    },
  },
}));

interface CustomizedBreadcrumbsProps {
  id: string;
}

export const createBreadcrumb = (
  id: string,
  to: string,
  label: string,
  icon?: React.ReactElement
): React.ReactElement => (
  <StyledBreadcrumb
    id={id}
    component={RouterLink}
    to={to}
    label={label}
    icon={icon}
  />
);

const CustomizedBreadcrumbs: React.FC<CustomizedBreadcrumbsProps> = ({
  id,
}) => {
  const { userProfileData } = useSelector((state: any) => state?.profile);
  const clientName = userProfileData?.data?.client_details?.client_name || '';

  const classes = useStyles();

  return (
    <Box className={classes.container} id={id}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        {createBreadcrumb(
          `${id}-home`,
          CONSTANTS.NAVIGATE_TO_DASHBOARD,
          '',
          <img src={HomeIcon} alt="homeIcon" />
        )}
        {createBreadcrumb(
          `${id}-dashboard`,
          CONSTANTS.NAVIGATE_TO_DASHBOARD,
          `Dashboard - ${clientName}`
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default CustomizedBreadcrumbs;
