/* eslint-disable prettier/prettier */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import CONSTANTS from '../../../constants/constants';
import colors from '../../../theme/colors.json';

interface DeleteConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: `${colors.BRIGHT_BLUE} !important`,
    textAlign: 'left',
    fontSize: '1rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },

  dialogActions: {
    justifyContent: 'center',
  },
  responsiveDialog: {
    height: 'auto',
    width: '70vw',
  },
}));

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title = `${CONSTANTS.IS_DELETE_CLIENT}`,
  description = `${CONSTANTS.DELETE_CLIENT_CONFIRMATION_MSG}`,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      classes={{ paper: classes.responsiveDialog }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        className={classes.dialogTitle}
        id={`delete-confirmation-title-${title}`}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2">{description}</Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton
          id={'deleteCancelBtn'}
          label={CONSTANTS.CANCEL_BTN_TEXT}
          onClick={onClose}
          backgroundColor={colors.TRANSPARENT}
          textColor={colors.BRIGHT_BLUE}
        />
        <CustomButton
          id={'deleteConfirmBtn'}
          textColor={colors.BRIGHT_BLUE}
          backgroundColor={colors.TRANSPARENT}
          label={CONSTANTS.CONFIRM_BTN_TEXT}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
