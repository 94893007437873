import { ReactComponent as Hits } from '../../assets/icons/svg/hits.svg';
import { ReactComponent as AttacksBlocked } from '../../assets/icons/svg/attacksBlocked.svg';
import { ReactComponent as Bandwidth } from '../../assets/icons/svg/bandwidth.svg';
import { ReactComponent as RulesTriggered } from '../../assets/icons/svg/rulesTriggered.svg';

export const initialStatsData = [
  {
    id: 'total_hits',
    title: 'Total Hits',
    value: 0,
    description: 'grow since last month',
    icon: Hits,
    isLoading: true,
    growthRate: 0,
  },
  {
    id: 'total_attacks',
    title: 'Attacks Blocked',
    value: 0,
    description: 'grow since last scan',
    icon: AttacksBlocked,
    isLoading: true,
    growthRate: 0,
  },
  {
    id: 'attack_percentage',
    title: 'Attack Percentage',
    value: 0,
    description: 'grow since last month',
    icon: AttacksBlocked,
    isLoading: true,
    growthRate: 0,
  },
  {
    id: 'bandwidth',
    title: 'Consumed Bandwidth',
    value: 0,
    description: 'grow since last month',
    icon: Bandwidth,
    isLoading: true,
    growthRate: 0,
  },
  {
    id: 'rules_triggered',
    title: 'Rules Triggered',
    value: 0,
    description: 'grow since last month',
    icon: RulesTriggered,
    isLoading: true,
    growthRate: 0,
  },
];
