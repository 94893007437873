/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Refresh } from '../../../assets/icons/svg/refresh.svg';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import Logo from './Logo';
import { useDispatch, useSelector } from 'react-redux';
import CONSTANTS from '../../../constants/constants';
import { getMenusApi } from '../../../services/appServices';
import { domainsByClientApi } from '../../../services/dashboardApis';
import { setIsRefresh } from '../../../redux/loader/loadingActions';
import { getSessionStorageItem } from '../../../encrypt';
import colors from '../../../theme/colors.json';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    width: '100% !important',
    backgroundColor: colors.WHITE,
    borderBottom: `1px solid ${colors.BORDER_1}`,
    minHeight: '40px !important',
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px !important',
    },
    '@media (min-width:2560px)': {
      minHeight: '80px !important',
    },
    '@media (min-width:3840px)': {
      minHeight: '90px !important',
    },
  },
  rightSectionBox: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(600)]: {
      minWidth: 240,
      width: '40%',
    },
    [theme.breakpoints.down(400)]: {
      minWidth: 180,
      width: '30%',
      textAlign: 'right',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: 140,
      width: '30%',
      textAlign: 'right',
    },
    [theme.breakpoints.down('md')]: {
      width: '45%',
      justifyContent: 'flex-end',
    },
  },
  rightSection: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  blockedMode: {
    color: colors.RED,
    marginRight: theme.spacing(2),
  },
  time: {
    color: colors.TX_COLOR_5,
  },
  refreshBtn: {
    cursor: 'pointer',
    paddingLeft: '0.8rem !important',
  },
}));

interface HeaderProps {
  id: string;
}

const Header: React.FC<HeaderProps> = ({ id }) => {
  const classes = useStyles();

  const { domain } = useSelector((state: any) => state?.domainData);
  const { isRefresh } = useSelector((state: any) => state?.loaders);

  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(new Date()?.toLocaleString());

  const domainInStorage: any = getSessionStorageItem(CONSTANTS.DOMAIN_OBJ);

  const storedDomain = domainInStorage ? JSON.parse(domainInStorage) : domain;

  const isDashboard =
    window?.location?.pathname === CONSTANTS.NAVIGATE_TO_DASHBOARD;

  useEffect(() => {
    setCurrentTime(new Date().toLocaleString());
  }, [isRefresh]);

  useEffect(() => {
    getMenusApi(dispatch);
    domainsByClientApi(dispatch);
  }, [dispatch]);
  const handleRefreshClick = () => {
    dispatch(setIsRefresh());
  };

  return (
    <div id={id} data-testid={`${id}-appBar`} className={classes.root}>
      <AppBar id={`${id}-appBar`} position="static">
        <Toolbar id={`${id}-toolbar`} className={classes.toolbar}>
          <Logo id={`${id}-logo`} />
          <div className={classes.title}></div>
          {isDashboard && (
            <div className={classes.rightSectionBox}>
              <div className={classes.rightSection}>
                <Typography
                  variant="h4"
                  color={colors.CORAL_RED}
                  data-testid={`${id}-blockedMode`}
                  id={`${id}-blockedMode`}
                  className={classes.blockedMode}
                >
                  {storedDomain?.current_mode === CONSTANTS.MODE_ON
                    ? CONSTANTS.BLOCK_MODE
                    : CONSTANTS.TRANSPARENT_MODE}
                </Typography>
                <Typography
                  variant="h4"
                  id={`${id}-time`}
                  className={classes.time}
                >
                  Last Updated: {currentTime}
                </Typography>
              </div>
              <IconButton
                className={classes.refreshBtn}
                aria-label="Refresh"
                title={CONSTANTS.REFRESH_BTN_TEXT}
                data-testid="refreshButton"
                id="refreshButton"
                onClick={handleRefreshClick}
                size="small"
              >
                <Refresh />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
