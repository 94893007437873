import colors from '../../theme/colors.json';
export const getSeverityColor = (severity) => {
  switch (severity.toLowerCase()) {
    case 'high':
      return colors.CORAL_PINK;
    case 'medium':
      return colors.SAFFRON_1;
    case 'low':
      return colors.FERN_GREEN;
    default:
      return 'default';
  }
};

export const getStatusColor = () => 'currentColor';

export const owaspTop10Colors = [
  colors.BRIGHT_TEAL,
  colors.VIVID_BLUE,
  colors.LAVENDER_BLUE,
  colors.VIBRANT_PURPLE,
  colors.VIBRANT_ORANGE,
  colors.BRIGHT_YELLOW,
  colors.APPLE_GREEN,
  colors.RICH_GREEN,
  colors.DEEP_BLUE,
  colors.VERMILION,
  colors.AZURE_BLUE,
  colors.VIVID_PINK,
  colors.GOLDEN_ROD,
  colors.BRIGHT_YELLOW_1,
  colors.GOLDEN_ROD,
  colors.GOLDEN_YELLOW,
];

export const getRandomPastelColor = () => {
  const hue = Math.floor(
    (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * 100
  );
  return `hsl(${hue}, 50%, 50%)`;
};
